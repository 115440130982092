import './HeroLinks.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { mdiArrowRightThin } from '@mdi/js';
import Icon from '@mdi/react';
const arrow = <Icon path={mdiArrowRightThin} size={2} />;

export default function HeroLinks(props) {
	return (
		<Link className="herolink" to={props.link}>
			<div className="herolink__icon">{props.icon}</div>
			<div className="herolink__topic">{props.topic}</div>
			<p className="herolink__p">{props.text}</p>
			<div className="herolink__arrow">{arrow}</div>
		</Link>
	);
}
