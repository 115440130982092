import React from 'react';
import './Footer.scss';

function Footer() {
	return (
		<div className="footer">
			<div className="footer__container">
				<div>
					<a href="https://makalosa.org" className="navbar-makalosa">
						<img
							src="/images/makalosa_logga.png"
							alt="Besök hemsidan Sveriges Makalösa Föräldrar"
							className="navbar-makalosa-img"
						></img>
					</a>
				</div>
				<div>
					<p className="footer__p">
						Separationskollen är ett hjälpmedel för dig som ska eller har
						separerat. <br></br>
						Titta på experternas svar på de vanligaste frågorna vid
						separationer.
						<br></br>Separationskollen är en del av Sveriges Makalösa Föräldrar.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Footer;
