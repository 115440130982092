import { render } from 'react-dom';
import { useEffect } from 'react';
import {
	BrowserRouter,
	Routes,
	Route,
	Outlet,
	useLocation,
} from 'react-router-dom';
import App from './App';
import Skulder from './routes/skulder';
import Kriser from './routes/kriser';
import Juridiker from './routes/juridiker';

import Skuld from './routes/skuld';
import Question from './routes/question';
import Kris from './routes/kris';
import Juridik from './routes/juridik';
import FamiljeriForandring from './routes/familjeriforandring';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';

export default function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [pathname]);

	return null;
}

const rootElement = document.getElementById('root');
render(
	<BrowserRouter>
		<ScrollToTop />
		<Routes>
			<Route path="/" element={<App />} />
			<Route path="familjer-i-forandring" element={<FamiljeriForandring />} />
			<Route path="ekonomi/*" element={<Skulder />}></Route>
			<Route path="ekonomi/:skuldId/*" element={<Skuld />}>
				<Route
					path="ekonomi/:skuldId/:questionId/*"
					element={<Question />}
				></Route>
			</Route>

			<Route path="mental-halsa/*" element={<Kriser />}></Route>
			<Route path="mental-halsa/:skuldId/*" element={<Kris />}>
				<Route
					path="mental-halsa/:skuldId/:questionId/*"
					element={<Question />}
				></Route>
			</Route>

			<Route path="juridik/*" element={<Juridiker />}></Route>
			<Route path="juridik/:skuldId/*" element={<Juridik />}>
				<Route
					path="juridik/:skuldId/:questionId/*"
					element={<Question />}
				></Route>
			</Route>

			<Route
				path="*"
				element={
					<>
						<Navbar />
						<main className="nopage">
							<Helmet>
								<title>404 Not Found</title>
							</Helmet>

							<h2>Sidan hittas inte, fel 404 Not Found</h2>
							<p>
								Sidan du letar efter finns inte längre. Använd menyn för att
								hitta rätt igen, eller
								<a href="/"> klicka här för att återgå till Startsidan</a> och
								se om du kan hitta det du letar efter.
							</p>
						</main>
					</>
				}
			/>
			{/* </Route> */}
		</Routes>
		<Footer />
		<Outlet />
	</BrowserRouter>,
	rootElement
);
//READ THIS
//https://ui.dev/react-router-nested-routes/
