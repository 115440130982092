import { useParams, Routes, Route, NavLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import QuestionKris from './questionKris';
import './skuld.scss';
import '../components/loader.scss';
import Profile from '../components/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Kris() {
	const { skuldId } = useParams();

	const [kriser, setKriser] = useState([]);
	const [kris, setKris] = useState({});
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchSkulder = async () => {
			setLoader(true);
			await fetch(
				'https://wp.separationskollen.se/wp-json/wp/v2/psykologi?per_page=100'
			)
				.then((response) => response.json())
				.then((response) => {
					setKriser(response);
					setKris(response.find((kris) => kris.acf.under_kategori === skuldId));

					setLoader(false);
				})
				.catch((err) => console.error(err));
			return;
		};

		fetchSkulder();
	}, []);

	const arrow = <FontAwesomeIcon icon={faArrowRight} />;
	return (
		<>
			<Navbar />

			<div className="course__container">
				{loader ? (
					<div className="loader_box">
						<span className="loader"></span>
					</div>
				) : (
					''
				)}
				{kris.acf ? (
					<div className="course">
						<Helmet>
							<title>{`${kris.acf.topic} - Mental hälsa - Separationskollen`}</title>
							<meta
								name="title"
								content={`${kris.acf.topic} - Mental hälsa - Separationskollen`}
							/>
							<meta name="description" content={kris.acf.topic} />
							<link
								rel="canonical"
								href={`https://separationskollen.se/mental-halsa/${skuldId}`}
							/>
							<meta
								property="og:url"
								content={`https://separationskollen.se/mental-halsa/${skuldId}`}
							/>
							<meta property="og:type" content="website" />
							<meta
								property="og:title"
								content={`${kris.acf.topic} - Mental hälsa - Separationskollen`}
							/>
							<meta property="og:description" content={kris.acf.topic} />
							<meta
								property="og:image"
								content={kris.acf.bild_pa_intervjupersonen}
							/>

							<meta
								name="robots"
								content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
							/>
							<meta property="twitter:card" content="summary_large_image" />
							<meta
								property="twitter:url"
								content={`https://separationskollen.se/mental-halsa/${skuldId}`}
							/>
							<meta
								property="twitter:title"
								content={`${kris.acf.topic} - Mental hälsa - Separationskollen`}
							/>
							<meta property="twitter:description" content={kris.acf.topic} />
							<meta
								property="twitter:image"
								content={kris.acf.bild_pa_intervjupersonen}
							/>
						</Helmet>
						<Routes className="course__main">
							<Route
								path=":questionId"
								element={
									<QuestionKris kriser={kriser} kris={kris} setKris={setKris} />
								}
							/>{' '}
							<Route
								index
								element={
									<main className="story__container">
										<Profile
											name={kris.acf.intervju_personens_namn}
											about={kris.acf.om_intervjupersonen}
											image={kris.acf.bild_pa_intervjupersonen}
										/>

										<div className="course__video-textbox">
											{kris.next ? (
												<a
													className="course__video-textbox-link"
													href={kris.next}
													key={kris.id}
												>
													Nästa fråga {arrow}
												</a>
											) : (
												''
											)}
											<h2 className="course__video-textbox-h2">{kris.topic}</h2>
											{/* <p className="course__video-textbox-p">{skuld.answer}</p> */}
										</div>
									</main>
								}
							/>
						</Routes>
						<nav className="course__ul">
							<h2 className="title-h2">
								Svar från {kris.acf.intervju_personens_namn}
							</h2>

							{kriser
								.filter((kris) => kris.acf.under_kategori === skuldId)
								.map((kris) => (
									<NavLink
										className={(navData) =>
											navData.isActive ? 'course__link' : ''
										}
										key={kris.id}
										to={kris.slug}
										role="navigation"
									>
										<div className="course__li" key={kris.id}>
											{kris.title.rendered}
										</div>
									</NavLink>
								))}
						</nav>
					</div>
				) : (
					<div></div>
				)}
			</div>
		</>
	);
}
