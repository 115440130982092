import { useEffect, useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Skuld from './skuld';
import { Helmet } from 'react-helmet';
import './skuld.scss';

export default function Skulder() {
	const [skulder, setSkulder] = useState([]);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchSkulder = async () => {
			setLoader(true);

			await fetch(
				'https://wp.separationskollen.se/wp-json/wp/v2/ekonomi?per_page=100'
			)
				.then((response) => response.json())
				.then((response) => {
					setSkulder(response);
					//console.log(response);
					setLoader(false);
				})
				.catch((err) => console.error(err));
			return;
		};

		fetchSkulder();
	}, []);

	function onlyUnique(value, index, self) {
		return (
			self.map((skuld) => skuld.acf.tipsfrom).indexOf(value.acf.tipsfrom) ===
			index
		);
	}

	return (
		<>
			<Helmet>
				<title>Ekonomi - Separationskollen</title>
				<meta name="title" content="Ekonomi - Separationskollen" />
				<meta
					name="description"
					content="Råd angående underhåll, bostadsbidrag och ekonomisk oro"
				/>
				<link rel="canonical" href={`https://separationskollen.se/ekonomi`} />
				<meta
					property="og:url"
					content={`https://separationskollen.se/ekonomi`}
				/>
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Ekonomi - Separationskollen" />
				<meta
					property="og:description"
					content="Råd angående underhåll, bostadsbidrag och ekonomisk oro"
				/>
				<meta
					property="og:image"
					content="https://wp.separationskollen.se/wp-content/uploads/2022/12/logo512.png"
				/>

				<meta
					name="robots"
					content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content={`https://separationskollen.se/ekonomi`}
				/>
				<meta property="twitter:title" content="Ekonomi - Separationskollen" />
				<meta
					property="twitter:description"
					content="Råd angående underhåll, bostadsbidrag och ekonomisk oro"
				/>
				<meta
					property="twitter:image"
					content="https://wp.separationskollen.se/wp-content/uploads/2022/12/logo512.png"
				/>
			</Helmet>
			<Navbar />

			<div className="pathgroup">
				{loader ? (
					<div className="loader_box">
						<span className="loader"></span>
					</div>
				) : (
					''
				)}
				{skulder.filter(onlyUnique).map((skuld) => (
					<Link
						className="pathgroup__box"
						to={skuld.acf.under_kategori}
						key={skuld.id}
					>
						<div className="pathgroup__link"> {skuld.acf.topic}</div>
					</Link>
				))}
			</div>

			<Routes>
				<Route path=":skuldId/*" element={<Skuld />} />
			</Routes>
		</>
	);
}
