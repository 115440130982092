import { useParams, Routes, Route, NavLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
//import SideNav from '../components/SideNav';
//import { getKris } from '../krisData';
import QuestionJuridik from './questionJuridik';
import './skuld.scss';
import '../components/loader.scss';

import Profile from '../components/profile';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Juridik() {
	const { skuldId } = useParams();

	const [juridiker, setJuridiker] = useState([]);
	const [juridik, setJuridik] = useState({});
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchJuridiker = async () => {
			setLoader(true);

			await fetch(
				'https://wp.separationskollen.se/wp-json/wp/v2/juridik?per_page=100'
			)
				.then((response) => response.json())
				.then((response) => {
					setJuridiker(response);
					setJuridik(
						response.find((juridik) => juridik.acf.under_kategori === skuldId)
					);

					setLoader(false);
				})
				.catch((err) => console.error(err));
			return;
		};

		fetchJuridiker();
	}, []);

	//const arrow = <FontAwesomeIcon icon={faArrowRight} />;
	return (
		<>
			<Navbar />
			<div className="course__container">
				{loader ? (
					<div className="loader_box">
						<span className="loader"></span>
					</div>
				) : (
					''
				)}
				{juridik.acf ? (
					<div className="course">
						<Helmet>
							<title>{`${juridik.acf.topic} - Juridik - Separationskollen`}</title>
							<meta
								name="title"
								content={`${juridik.acf.topic} - Juridik - Separationskollen`}
							/>
							<meta name="description" content={juridik.acf.topic} />
							<link
								rel="canonical"
								href={`https://separationskollen.se/juridik/${skuldId}`}
							/>
							<meta
								property="og:url"
								content={`https://separationskollen.se/juridik/${skuldId}`}
							/>
							<meta property="og:type" content="website" />
							<meta
								property="og:title"
								content={`${juridik.acf.topic} - Juridik - Separationskollen`}
							/>
							<meta property="og:description" content={juridik.acf.topic} />
							<meta
								property="og:image"
								content={juridik.acf.bild_pa_intervjupersonen}
							/>

							<meta
								name="robots"
								content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
							/>
							<meta property="twitter:card" content="summary_large_image" />
							<meta
								property="twitter:url"
								content={`https://separationskollen.se/juridik/${skuldId}`}
							/>
							<meta
								property="twitter:title"
								content={`${juridik.acf.topic} - Juridik - Separationskollen`}
							/>
							<meta
								property="twitter:description"
								content={juridik.acf.topic}
							/>
							<meta
								property="twitter:image"
								content={juridik.acf.bild_pa_intervjupersonen}
							/>
						</Helmet>
						<Routes className="course__main">
							<Route
								path=":questionId"
								element={
									<QuestionJuridik
										juridiker={juridiker}
										juridik={juridik}
										setJuridik={setJuridik}
									/>
								}
							/>{' '}
							<Route
								index
								element={
									<main className="story__container">
										<Profile
											name={juridik.acf.intervju_personens_namn}
											about={juridik.acf.om_intervjupersonen}
											image={juridik.acf.bild_pa_intervjupersonen}
										/>
									</main>
								}
							/>
						</Routes>
						<nav className="course__ul">
							<h2 className="title-h2">
								Svar från {juridik.acf.intervju_personens_namn}
							</h2>

							{juridiker
								.filter((juridik) => juridik.acf.under_kategori === skuldId)
								.map((juridik) => (
									<NavLink
										className={(navData) =>
											navData.isActive ? 'course__link' : ''
										}
										key={juridik.id}
										to={juridik.slug}
										role="navigation"
									>
										<div className="course__li" key={juridik.id}>
											{juridik.title.rendered}
										</div>
									</NavLink>
								))}
						</nav>
					</div>
				) : (
					<div></div>
				)}
			</div>
		</>
	);
}
