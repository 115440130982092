import { NavLink, useParams } from 'react-router-dom';
//import { getKrisQuestion } from '../krisData';
import './skuld.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ProfileQuestion from '../components/profilequestion';

export default function QuestionKris({ kriser, kris, setKris }) {
	const { skuldId, questionId } = useParams();
	//console.log(skuldId);
	//console.log(questionId);

	const arrow = <FontAwesomeIcon icon={faArrowRight} />;
	//console.log(id);

	useEffect(() => {
		setKris(kriser.find((kris) => kris.slug === questionId));
	}, [questionId]);
	return (
		<>
			<Helmet>
				<title>{kris.yoast_head_json.title}</title>
				<meta name="title" content={kris.yoast_head_json.title} />
				<meta
					name="description"
					content={kris.yoast_head_json.og_description}
				/>
				<link
					rel="canonical"
					href={`https://separationskollen.se/mental-halsa/${skuldId}/${questionId}`}
				/>
				<meta
					property="og:url"
					content={`https://separationskollen.se/mental-halsa/${skuldId}/${questionId}`}
				/>
				<meta property="og:type" content={kris.yoast_head_json.og_type} />
				<meta property="og:title" content={kris.yoast_head_json.og_title} />
				<meta
					property="og:description"
					content={kris.yoast_head_json.og_description}
				/>
				<meta property="og:image" content={kris.acf.bild_pa_intervjupersonen} />

				<meta
					name="robots"
					content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content={`https://separationskollen.se/mental-halsa/${skuldId}/${questionId}`}
				/>
				<meta
					property="twitter:title"
					content={kris.yoast_head_json.og_title}
				/>
				<meta
					property="twitter:description"
					content={kris.yoast_head_json.og_description}
				/>
				<meta
					property="twitter:image"
					content={kris.acf.bild_pa_intervjupersonen}
				/>
			</Helmet>
			<div key={kris.id} className="course__main">
				{kris.acf.video ? (
					<video className="course__video" controls autoPlay>
						<source src={kris.acf.video} type="video/mp4" />
					</video>
				) : (
					<h2>Tyvärr saknar din webbläsare stöd för video</h2>
				)}

				<div className="course__video-textbox">
					{kris.acf.next ? (
						<NavLink className="course__video-textbox-link" to={kris.acf.next}>
							Nästa fråga {arrow}
						</NavLink>
					) : (
						''
					)}
					<h1 className="course__video-textbox-h1">{kris.title.rendered}</h1>
					{/* <p className="course__video-textbox-p">{answer}</p> */}
					<div
						dangerouslySetInnerHTML={{ __html: kris.content.rendered }}
					></div>
					{kris.acf.pdf ? (
						<a
							className="course__video-textbox-link"
							href={kris.acf.pdf}
							target="_blank"
							rel="noreferrer noopener"
						>
							Ladda ner PDF
						</a>
					) : (
						''
					)}
					<ProfileQuestion
						name={kris.acf.intervju_personens_namn}
						about={kris.acf.om_intervjupersonen}
						image={kris.acf.bild_pa_intervjupersonen}
					/>{' '}
				</div>
			</div>
		</>
	);
}
