import { mdiGavel, mdiHeadSnowflake, mdiBank } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import './HeroSection.scss';
import NavbarLogo from './NavbarLogo';
import HeroLinks from './HeroLinks';
import { Helmet } from 'react-helmet';

function HeroSection() {
	const coins = <Icon path={mdiBank} size={2} />;
	const law = <Icon path={mdiGavel} size={2} />;
	const mentalHealth = <Icon path={mdiHeadSnowflake} size={2} />;

	return (
		<>
			<Helmet>
				<title>Separationskollen</title>
				<meta name="title" content="Separationskollen" />
				<meta
					name="description"
					content="Separationskollen är ett hjälpmedel för dig som ska eller har
						separerat. Titta på experternas svar på de vanligaste frågorna vid
						separationer."
				/>
				<link rel="canonical" href={`https://separationskollen.se/`} />
				<meta property="og:url" content={`https://separationskollen.se/`} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Separationskollen" />
				<meta
					property="og:description"
					content="Separationskollen är ett hjälpmedel för dig som ska eller har
						separerat. Titta på experternas svar på de vanligaste frågorna vid
						separationer."
				/>
				<meta
					property="og:image"
					content="https://makalosa.org/wp-content/uploads/2023/01/banner3.png"
				/>

				<meta
					name="robots"
					content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content={`https://separationskollen.se/`}
				/>
				<meta property="twitter:title" content="Separationskollen" />
				<meta
					property="twitter:description"
					content="Separationskollen är ett hjälpmedel för dig som ska eller har
						separerat. Titta på experternas svar på de vanligaste frågorna vid
						separationer."
				/>
				<meta
					property="twitter:image"
					content="https://makalosa.org/wp-content/uploads/2023/01/banner3.png"
				/>
			</Helmet>
			<header className="header">
				<div className="bg-video">
					<video className="bg-video__content" autoPlay muted loop playsInline>
						<source src="videos/headVideo.mp4" type="video/mp4" />
						Your browser is not supported!
					</video>
				</div>
				<div className="logo__hero">
					<NavbarLogo />
				</div>

				<div className="header__linkgroup">
					<HeroLinks
						link="/ekonomi"
						icon={coins}
						topic="Ekonomi"
						text="Råd angående underhåll, bostadsbidrag och ekonomisk oro"
					/>
					<HeroLinks
						link="/juridik"
						icon={law}
						topic="Juridik"
						text="Titta på experternas råd gällande våld, bodelning och vårdnadstvist"
					/>
					<HeroLinks
						link="/mental-halsa"
						icon={mentalHealth}
						topic="Mental hälsa"
						text="Titta på experternas råd gällande separationskris och samarbete"
					/>
				</div>
			</header>
		</>
	);
}

export default HeroSection;
