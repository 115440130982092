import { useEffect, useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';

import '../components/loader.scss';
import Juridik from './juridik';
import { Helmet } from 'react-helmet';

export default function Skulder() {
	const [juridiker, setJuridiker] = useState([]);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchJuridiker = async () => {
			setLoader(true);

			await fetch(
				'https://wp.separationskollen.se/wp-json/wp/v2/juridik?per_page=100'
			)
				.then((response) => response.json())
				.then((response) => {
					setJuridiker(response);
					//console.log(response);
					setLoader(false);
				})
				.catch((err) => console.error(err));
			return;
		};

		fetchJuridiker();
	}, []);

	function onlyUnique(value, index, self) {
		return (
			self.map((juridik) => juridik.acf.topic).indexOf(value.acf.topic) ===
			index
		);
	}

	return (
		<>
			<Helmet>
				<title>Juridik - Separationskollen</title>
				<meta name="title" content="Juridik - Separationskollen" />
				<meta
					name="description"
					content="Titta på experternas råd gällande våld, bodelning och vårdnadstvist"
				/>
				<link rel="canonical" href={`https://separationskollen.se/juridik`} />
				<meta
					property="og:url"
					content={`https://separationskollen.se/juridik`}
				/>
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Juridik - Separationskollen" />
				<meta
					property="og:description"
					content="Titta på experternas råd gällande våld, bodelning och vårdnadstvist"
				/>
				<meta
					property="og:image"
					content="https://wp.separationskollen.se/wp-content/uploads/2022/12/logo512.png"
				/>

				<meta
					name="robots"
					content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content={`https://separationskollen.se/juridik`}
				/>
				<meta property="twitter:title" content="Juridik - Separationskollen" />
				<meta
					property="twitter:description"
					content="Titta på experternas råd gällande våld, bodelning och vårdnadstvist"
				/>
				<meta
					property="twitter:image"
					content="https://wp.separationskollen.se/wp-content/uploads/2022/12/logo512.png"
				/>
			</Helmet>
			<Navbar />
			<div>
				{/* <h1 className="title-h1">Tips från:</h1> */}
				<div className="pathgroup">
					{loader ? (
						<div className="loader_box">
							<span className="loader"></span>
						</div>
					) : (
						''
					)}
					{juridiker.filter(onlyUnique).map((juridik) => (
						// <div className="pathgroup__box">
						<Link
							className="pathgroup__box"
							to={juridik.acf.under_kategori}
							key={juridik.id}
						>
							<div className="pathgroup__link"> {juridik.acf.topic}</div>
						</Link>
						// </div>
					))}
				</div>
				<Routes>
					<Route path=":skuldId/*" element={<Juridik />} />
				</Routes>
			</div>
		</>
	);
}
