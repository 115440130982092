import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
//import { Button } from './Button';
import './Navbar.scss';
import NavbarLogo from './NavbarLogo';

function Navbar() {
	const [click, setClick] = useState(false);
	const [button, setButton] = useState(true);
	const handleClick = () => setClick(!click);
	const closeMobileMenu = () => setClick(false);

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setButton(true);
		} else {
			setButton(true);
		}
	};

	//reders showButton only once
	useEffect(() => {
		showButton();
	}, []);

	window.addEventListener('resize', showButton);

	return (
		<nav className="navbar" role="navigation">
			<div className="navbar-container">
				<NavbarLogo sizeh="logo__h-nav" sizep="logo__p-nav" />
				<div className="menu-icon" onClick={handleClick}>
					<i className={click ? 'fas fa-times' : 'fas fa-bars'} />
				</div>
				<ul className={click ? 'nav-menu active' : 'nav-menu'}>
					<NavLink
						className={(navData) =>
							navData.isActive ? 'nav-links nav-links-active' : 'nav-links'
						}
						to="/ekonomi"
						//className="nav-links"
						onClick={closeMobileMenu}
					>
						Ekonomi
					</NavLink>
					<NavLink
						className={(navData) =>
							navData.isActive ? 'nav-links nav-links-active' : 'nav-links'
						}
						to="/juridik"
						onClick={closeMobileMenu}
					>
						Juridik
					</NavLink>
					<NavLink
						className={(navData) =>
							navData.isActive ? 'nav-links nav-links-active' : 'nav-links'
						}
						to="/mental-halsa"
						onClick={closeMobileMenu}
					>
						Mental Hälsa
					</NavLink>
				</ul>
			</div>
		</nav>
	);
}
export default Navbar;
