import './profile.scss';
import React from 'react';

export default function ProfileQuestion(props) {
	return (
		<div className="story-Q">
			<figure className="story__shape-Q">
				<img src={props.image} alt={props.name} className="story__image" />
				<figcaption className="story__caption">{props.name}</figcaption>
			</figure>
			<div className="story__text-Q">
				<h3 className="">{props.name}</h3>
				<p>{props.about}</p>
			</div>
		</div>
	);
}
