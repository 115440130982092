import { NavLink, useParams } from 'react-router-dom';
import './skuld.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ProfileQuestion from '../components/profilequestion';

export default function Question({ skulder, skuld, setSkuld }) {
	const { skuldId, questionId } = useParams();

	const arrow = <FontAwesomeIcon icon={faArrowRight} />;

	useEffect(() => {
		//console.log(questionId);
		setSkuld(skulder.find((skuld) => skuld.slug === questionId));
	}, [questionId]);

	return (
		<>
			<Helmet>
				<title>{skuld.yoast_head_json.title}</title>
				<meta name="title" content={skuld.yoast_head_json.title} />
				<meta
					name="description"
					content={skuld.yoast_head_json.og_description}
				/>
				<link
					rel="canonical"
					href={`https://separationskollen.se/ekonomi/${skuldId}/${questionId}`}
				/>
				<meta
					property="og:url"
					content={`https://separationskollen.se/ekonomi/${skuldId}/${questionId}`}
				/>
				<meta property="og:type" content={skuld.yoast_head_json.og_type} />
				<meta property="og:title" content={skuld.yoast_head_json.og_title} />
				<meta
					property="og:description"
					content={skuld.yoast_head_json.og_description}
				/>
				<meta
					property="og:image"
					content={skuld.acf.bild_pa_intervjupersonen}
				/>

				<meta
					name="robots"
					content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content={`https://separationskollen.se/ekonomi/${skuldId}/${questionId}`}
				/>
				<meta
					property="twitter:title"
					content={skuld.yoast_head_json.og_title}
				/>
				<meta
					property="twitter:description"
					content={skuld.yoast_head_json.og_description}
				/>
				<meta
					property="twitter:image"
					content={skuld.acf.bild_pa_intervjupersonen}
				/>
			</Helmet>
			<div key={skuld.id} className="course__main">
				{skuld.acf.video ? (
					<video className="course__video" controls autoPlay>
						<source src={skuld.acf.video} type="video/mp4" />
					</video>
				) : (
					<h2>Tyvärr saknar din webbläsare stöd för video</h2>
				)}
				<div className="course__video-textbox">
					{skuld.acf.next ? (
						<NavLink className="course__video-textbox-link" to={skuld.acf.next}>
							Nästa fråga {arrow}
						</NavLink>
					) : (
						''
					)}
					<h1 className="course__video-textbox-h1">{skuld.title.rendered}</h1>
					<div
						dangerouslySetInnerHTML={{ __html: skuld.content.rendered }}
					></div>
					{skuld.acf.pdf ? (
						<a
							className="course__video-textbox-link"
							href={skuld.acf.pdf}
							target="_blank"
							rel="noreferrer noopener"
						>
							Ladda ner PDF
						</a>
					) : (
						''
					)}
					<ProfileQuestion
						name={skuld.acf.intervju_personens_namn}
						about={skuld.acf.om_intervjupersonen}
						image={skuld.acf.bild_pa_intervjupersonen}
					/>{' '}
				</div>
			</div>
		</>
	);
}
