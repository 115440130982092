import React from 'react';
import { Link } from 'react-router-dom';
import './NavbarLogo.scss';

function NavbarLogo(props) {
	return (
		<Link to="/" className="logo">
			<h1 className={`logo__h ${props.sizeh}`}>separationskollen</h1>
			<p className={`logo__p ${props.sizep}`}>din guide vid separation</p>
		</Link>
	);
}
export default NavbarLogo;
