import { useEffect, useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Kris from './kris';
import '../components/loader.scss';
import { Helmet } from 'react-helmet';

export default function Kriser() {
	const [kriser, setKriser] = useState([]);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchKriser = async () => {
			setLoader(true);
			await fetch(
				'https://wp.separationskollen.se/wp-json/wp/v2/psykologi?per_page=100'
			)
				.then((response) => response.json())
				.then((response) => {
					setKriser(response);
					//console.log(response);
					setLoader(false);
				})
				.catch((err) => console.error(err));
			return;
		};

		fetchKriser();
	}, []);

	function onlyUnique(value, index, self) {
		return (
			self.map((kris) => kris.acf.topic).indexOf(value.acf.topic) === index
		);
	}

	return (
		<>
			<Helmet>
				<title>Mental hälsa - Separationskollen</title>
				<meta name="title" content="Mental hälsa - Separationskollen" />
				<meta
					name="description"
					content="Titta på experternas råd gällande separationskris och samarbete"
				/>
				<link
					rel="canonical"
					href={`https://separationskollen.se/mental-halsa`}
				/>
				<meta
					property="og:url"
					content={`https://separationskollen.se/mental-halsa`}
				/>
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Mental hälsa - Separationskollen" />
				<meta
					property="og:description"
					content="Titta på experternas råd gällande separationskris och samarbete"
				/>
				<meta
					property="og:image"
					content="https://wp.separationskollen.se/wp-content/uploads/2022/12/logo512.png"
				/>

				<meta
					name="robots"
					content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
				/>
				<meta property="twitter:card" content="summary_large_image" />
				<meta
					property="twitter:url"
					content={`https://separationskollen.se/mental-halsa`}
				/>
				<meta
					property="twitter:title"
					content="Mental hälsa - Separationskollen"
				/>
				<meta
					property="twitter:description"
					content="Titta på experternas råd gällande separationskris och samarbete"
				/>
				<meta
					property="twitter:image"
					content="https://wp.separationskollen.se/wp-content/uploads/2022/12/logo512.png"
				/>
			</Helmet>
			<Navbar />
			<div>
				{/* <h1 className="title-h1">Tips från:</h1> */}
				<div className="pathgroup">
					{loader ? (
						<div className="loader_box">
							<span className="loader"></span>
						</div>
					) : (
						''
					)}
					{kriser.filter(onlyUnique).map((kris) => (
						// <div className="pathgroup__box">
						<Link
							className="pathgroup__box"
							to={kris.acf.under_kategori}
							key={kris.id}
						>
							<div className="pathgroup__link"> {kris.acf.topic}</div>
						</Link>
						// </div>
					))}
					{loader ? (
						''
					) : (
						<Link
							className="pathgroup__box"
							to="/familjer-i-forandring"
							key="famifor"
							style={{ backgroundColor: 'blue' }}
						>
							<div className="pathgroup__link">
								Kursen Familjer i Förändring
							</div>
						</Link>
					)}
				</div>
				<Routes>
					<Route path=":skuldId/*" element={<Kris />} />
				</Routes>
			</div>
		</>
	);
}
