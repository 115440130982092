import { useEffect, useState } from 'react';
import { useParams, Routes, Route, NavLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Profile from '../components/profile';
import Question from './question';
import './skuld.scss';
import '../components/loader.scss';
import { Helmet } from 'react-helmet';

export default function Skuld() {
	const { skuldId } = useParams();
	const [skulder, setSkulder] = useState([]);
	const [skuld, setSkuld] = useState({});
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		const fetchSkulder = async () => {
			setLoader(true);

			await fetch(
				'https://wp.separationskollen.se/wp-json/wp/v2/ekonomi?per_page=100'
			)
				.then((response) => response.json())
				.then((response) => {
					setSkulder(response);
					setSkuld(
						response.find((skuld) => skuld.acf.under_kategori === skuldId)
					);

					setLoader(false);
				})
				.catch((err) => console.error(err));
			return;
		};

		fetchSkulder();
	}, []);

	return (
		<>
			<Navbar />

			<div className="course__container">
				{loader ? (
					<div className="loader_box">
						<span className="loader"></span>
					</div>
				) : (
					''
				)}
				{skuld.acf ? (
					<div className="course">
						<Helmet>
							<title>{`${skuld.acf.topic} - Ekonomi - Separationskollen`}</title>
							<meta
								name="title"
								content={`${skuld.acf.topic} - Ekonomi - Separationskollen`}
							/>
							<meta name="description" content={skuld.acf.topic} />
							<link
								rel="canonical"
								href={`https://separationskollen.se/ekonomi/${skuldId}`}
							/>
							<meta
								property="og:url"
								content={`https://separationskollen.se/ekonomi/${skuldId}`}
							/>
							<meta property="og:type" content="website" />
							<meta
								property="og:title"
								content={`${skuld.acf.topic} - Ekonomi - Separationskollen`}
							/>
							<meta property="og:description" content={skuld.acf.topic} />
							<meta
								property="og:image"
								content={skuld.acf.bild_pa_intervjupersonen}
							/>

							<meta
								name="robots"
								content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
							/>
							<meta property="twitter:card" content="summary_large_image" />
							<meta
								property="twitter:url"
								content={`https://separationskollen.se/ekonomi/${skuldId}`}
							/>
							<meta
								property="twitter:title"
								content={`${skuld.acf.topic} - Ekonomi - Separationskollen`}
							/>
							<meta property="twitter:description" content={skuld.acf.topic} />
							<meta
								property="twitter:image"
								content={skuld.acf.bild_pa_intervjupersonen}
							/>
						</Helmet>
						<Routes className="course__main">
							<Route
								path=":questionId"
								element={
									<Question
										skulder={skulder}
										skuld={skuld}
										setSkuld={setSkuld}
									/>
								}
							/>{' '}
							<Route
								index
								element={
									<main className="story__container">
										<Profile
											name={skuld.acf.intervju_personens_namn}
											about={skuld.acf.om_intervjupersonen}
											image={skuld.acf.bild_pa_intervjupersonen}
										/>
									</main>
								}
							/>
						</Routes>
						<nav className="course__ul">
							<h2 className="title-h2">
								Svar från {skuld.acf.intervju_personens_namn}
							</h2>

							{skulder
								.filter((skuld) => skuld.acf.under_kategori === skuldId)
								.map((skuld) => (
									<NavLink
										className={(navData) =>
											navData.isActive ? 'course__link' : ''
										}
										key={skuld.id}
										to={skuld.slug}
										role="navigation"
									>
										<div className="course__li" key={skuld.id}>
											{skuld.title.rendered}
										</div>
									</NavLink>
								))}
						</nav>
					</div>
				) : (
					<div></div>
				)}
			</div>
		</>
	);
}
